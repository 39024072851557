/* eslint-disable no-unused-expressions */
import React, { useState } from "react";

const Header = ({ cart, RemoveFromCart }) => {
  const calculateTotal = () => {
    let total = 0.0;

    cart?.map((c) => (total += c.price * c.quantity));
    return total;
  };
  const [opened, Open] = useState(false);
  return (
    <>
      <header className="mkdf-page-header">
        <div className="mkdf-menu-area mkdf-menu-right">
          <div className="mkdf-grid">
            <div className="mkdf-vertical-align-containers">
              <div className="mkdf-position-left">
                <div className="mkdf-position-left-inner">
                  <div className="mkdf-logo-wrapper">
                    <a itemprop="url" href="/" style={{ height: 25 }}>
                      {/*<img
                        itemprop="image"
                        className="mkdf-normal-logo"
                        src="wp-content/uploads/2018/05/h2-logo-1.png"
                        width="424"
                        height="50"
                        alt="logo"
                      />
                      <img
                        itemprop="image"
                        className="mkdf-dark-logo"
                        src="wp-content/themes/fiorello/assets/img/logo.png"
                        alt="dark logo"
                      />{" "}
                      <img
                        itemprop="image"
                        className="mkdf-light-logo"
                        src="wp-content/themes/fiorello/assets/img/logo_white.png"
                        alt="light logo"
                      />**/}
                      <p style={{ fontSize: 35, marginLeft: "5vw" }}>
                        <span style={{ color: "red" }}>Royal</span>
                        <span style={{ color: "black" }}>Flowers</span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="mkdf-position-right">
                <div className="mkdf-position-right-inner">
                  <nav className="mkdf-main-menu mkdf-drop-down mkdf-default-nav">
                    <ul id="menu-main-menu" className="clearfix">
                      <li
                        id="nav-menu-item-90"
                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub narrow"
                      >
                        <a href="/" className=" current ">
                          <span className="item_outer">
                            <span className="item_text">Anasayfa</span>
                            <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                          </span>
                        </a>
                      </li>

                      <li
                        id="nav-menu-item-704"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow"
                      >
                        <a href="/iletisim" className="">
                          <span className="item_outer">
                            <span className="item_text">İletişim</span>
                            <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>

                  <div className="mkdf-shopping-cart-holder mkdf-cart-info-is-disabled">
                    <div className="mkdf-shopping-cart-inner">
                      <a
                        itemprop="url"
                        className="mkdf-header-cart"
                        href="/sepet"
                      >
                        <span className="mkdf-cart-icon-number-holder">
                          <span className="mkdf-cart-icon">
                            <i className="mkdf-icon-dripicons dripicon dripicons-shopping-bag "></i>
                          </span>
                          <span className="mkdf-cart-number">
                            {cart?.length}
                          </span>
                        </span>
                        <span className="mkdf-cart-icon-text">SEPET</span>
                        <span className="mkdf-cart-info">
                          <span className="mkdf-cart-info-total">
                            (
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">
                                ₺
                              </span>
                              {calculateTotal()}
                            </span>
                            )
                          </span>
                        </span>
                      </a>
                      <div
                        className="mkdf-shopping-cart-dropdown"
                        style={{ zIndex: 1000 }}
                      >
                        <ul>
                          {cart.length > 0 ? (
                            cart.map((item) => (
                              <li>
                                <div class="mkdf-item-image-holder">
                                  <a
                                    itemprop="url"
                                    href="../../product/majesty-palm/index.html"
                                  >
                                    <img
                                      width="300"
                                      height="400"
                                      src={item.image}
                                      class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                      alt="l"
                                    />{" "}
                                  </a>
                                </div>
                                <div class="mkdf-item-info-holder">
                                  <h6
                                    itemprop="name"
                                    class="mkdf-product-title"
                                  >
                                    <a
                                      itemprop="url"
                                      href="../../product/majesty-palm/index.html"
                                    >
                                      {item.name}
                                    </a>
                                  </h6>
                                  <span class="mkdf-quantity">
                                    {item.quantity}
                                  </span>
                                  <span class="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span class="woocommerce-Price-currencySymbol">
                                        ₺
                                      </span>
                                      {item.price}
                                    </bdi>
                                  </span>{" "}
                                  <a
                                    onClick={() => RemoveFromCart(item)}
                                    class="remove"
                                    title="Sepetten Çıkar"
                                    style={{ cursor: "pointer", fontSize: 22 }}
                                  >
                                    <span class="icon-arrows-remove"></span>
                                  </a>{" "}
                                </div>
                              </li>
                            ))
                          ) : (
                            <li className="mkdf-empty-cart">
                              Sepette Ürün Yok
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mkdf-sticky-header">
          <div className="mkdf-sticky-holder mkdf-menu-right">
            <div className="mkdf-grid">
              <div className="mkdf-vertical-align-containers">
                <div className="mkdf-position-left">
                  <div className="mkdf-position-left-inner">
                    <div className="mkdf-logo-wrapper">
                      <a itemprop="url" href="/" style={{ height: 25 }}>
                        <p style={{ fontSize: 35, marginLeft: "5vw" }}>
                          <span style={{ color: "red" }}>Royal</span>
                          <span style={{ color: "black" }}>Flowers</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mkdf-position-right">
                  <div className="mkdf-position-right-inner">
                    <nav className="mkdf-main-menu mkdf-drop-down mkdf-sticky-nav">
                      <ul id="menu-main-menu-1" className="clearfix">
                        <li
                          id="nav-menu-item-90"
                          className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub narrow"
                        >
                          <a href="/" className=" current ">
                            <span className="item_outer">
                              <span className="item_text">Anasayfa</span>
                              <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                            </span>
                          </a>
                        </li>

                        <li
                          id="nav-menu-item-704"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow"
                        >
                          <a href="/iletisim" className="">
                            <span className="item_outer">
                              <span className="item_text">İletişim</span>
                              <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mkdf-slide-from-header-bottom-holder">
          <form action="https://fiorello.qodeinteractive.com/" method="get">
            <div className="mkdf-form-holder">
              <input
                type="text"
                placeholder="Search"
                name="s"
                className="mkdf-search-field"
                autocomplete="off"
              />
              <button
                type="submit"
                className="mkdf-search-submit mkdf-search-submit-icon-pack"
              >
                <span
                  aria-hidden="true"
                  className="mkdf-icon-font-elegant icon_search "
                ></span>{" "}
              </button>
            </div>
          </form>
        </div>
      </header>
      <header className="mkdf-mobile-header">
        <div className="mkdf-mobile-header-inner">
          <div className="mkdf-mobile-header-holder">
            <div className="mkdf-grid">
              <div className="mkdf-vertical-align-containers">
                <div className="mkdf-vertical-align-containers">
                  <div className="mkdf-position-left">
                    <div className="mkdf-position-left-inner">
                      <div className="mkdf-mobile-logo-wrapper">
                        <a href="/" style={{ height: 25 }}>
                          <p style={{ fontSize: 35, marginLeft: "5vw" }}>
                            <span style={{ color: "red" }}>Royal</span>
                            <span style={{ color: "black" }}>Flowers</span>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mkdf-position-right">
                    <div className="mkdf-position-right-inner">
                      <div
                        className="mkdf-mobile-menu-opener mkdf-mobile-menu-opener-icon-pack"
                        onClick={(e) => {
                          Open(true);
                        }}
                      >
                        <a href="javascript:void(0)">
                          <span className="mkdf-mobile-menu-icon">
                            <span
                              aria-hidden="true"
                              className="mkdf-icon-font-elegant icon_menu "
                            ></span>{" "}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            opened ? "mkdf-mobile-side-area opened " : "mkdf-mobile-side-area"
          }
        >
          <div
            className="mkdf-close-mobile-side-area-holder"
            onClick={() => Open(false)}
          >
            <i className="mkdf-icon-dripicons dripicon dripicons-cross "></i>{" "}
          </div>
          <div className="mkdf-mobile-side-area-inner">
            <nav className="mkdf-mobile-nav">
              <ul id="menu-main-menu-2" className="">
                <li
                  id="mobile-menu-item-90"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub"
                >
                  <a href="/sepet" className=" current  mkdf-mobile-no-link">
                    <span>Sepet</span>
                  </a>
                </li>
                <li
                  id="mobile-menu-item-90"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub"
                >
                  <a href="/" className=" current  mkdf-mobile-no-link">
                    <span>Ana Ekran</span>
                  </a>
                </li>
                <li
                  id="mobile-menu-item-90"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mkdf-active-item has_sub"
                >
                  <a href="/iletisim" className=" current  mkdf-mobile-no-link">
                    <span>İletişim</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

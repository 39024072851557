/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";

const Cart = ({ cart, AddToCart, RemoveFromCart, DeleteFromCart }) => {
  useEffect(() => {
    document.body.classList.add(
      "page-template-default",
      "page",
      "page-id-214",
      "theme-fiorello",
      "fiorello-core-1.2",
      "woocommerce-cart",
      "woocommerce-page",
      "woocommerce-js",
      "fiorello-ver-1.5",
      "mkdf-grid-1300",
      "mkdf-content-background-full-image",
      "mkdf-sticky-header-on-scroll-down-up",
      "mkdf-dropdown-animate-height",
      "mkdf-header-standard",
      "mkdf-menu-area-shadow-disable",
      "mkdf-menu-area-in-grid-shadow-disable",
      "mkdf-menu-area-border-disable",
      "mkdf-menu-area-in-grid-border-disable",
      "mkdf-logo-area-border-disable",
      "mkdf-woocommerce-page",
      "mkdf-woocommerce-columns-3",
      "mkdf-woo-medium-space",
      "mkdf-woo-pl-info-below-image",
      "mkdf-woo-single-thumb-on-left-side",
      "mkdf-woo-single-has-pretty-photo",
      "mkdf-default-mobile-header",
      "mkdf-sticky-up-mobile-header",
      "mkdf-slide-from-header-bottom",
      "wpb-js-composer",
      "js-comp-ver-6.6.0",
      "vc_responsive"
    );
    window.scrollTo(0, 0);
  }, []);
  const [quantity, setQuantity] = useState(1);
  const calculateTotal = () => {
    let total = 0.0;

    cart?.map((c) => (total += c.price * c.quantity));
    return total;
  };
  return (
    <div class="mkdf-wrapper">
      <div class="mkdf-wrapper-inner">
        <div class="mkdf-content">
          <div class="mkdf-content-inner">
            <div
              class="mkdf-title-holder mkdf-standard-type mkdf-title-va-header-bottom mkdf-title-content-va-middle"
              style={{ height: 225, backgroundColor: "#fafaf9" }}
              data-height="225"
            >
              <div class="mkdf-title-wrapper" style={{ height: 225 }}>
                <div class="mkdf-title-inner">
                  <div class="mkdf-grid">
                    <h1 class="mkdf-page-title entry-title">Sepet</h1>
                    <span class="mkdf-page-subtitle">
                      Where flowers are our inspiration
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mkdf-container mkdf-default-page-template">
              <div class="mkdf-container-inner clearfix">
                <div class="mkdf-grid-row">
                  <div class="mkdf-page-content-holder mkdf-grid-col-12">
                    <div class="wpb_column vc_column_container vc_col-sm-12">
                      <div class="vc_column-inner">
                        <div class="wpb_wrapper"></div>
                      </div>
                    </div>
                    <div class="woocommerce">
                      <div class="woocommerce-notices-wrapper"></div>
                      <form
                        class="woocommerce-cart-form"
                        action="https://fiorello.qodeinteractive.com/cart/"
                        method="post"
                      >
                        <table
                          class="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th class="product-remove">&nbsp;</th>
                              <th class="product-thumbnail">&nbsp;</th>
                              <th class="product-name">Ürün</th>
                              <th class="product-price">Fiyat</th>
                              <th class="product-quantity">Miktar</th>
                              <th class="product-subtotal">Toplam</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart?.map((item) => (
                              <tr class="woocommerce-cart-form__cart-item cart_item">
                                <td class="product-remove">
                                  <a
                                    onClick={() => {
                                      DeleteFromCart(item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    class="remove"
                                    aria-label="Remove this item"
                                    data-product_id="364"
                                    data-product_sku="74141"
                                  >
                                    ×
                                  </a>{" "}
                                </td>

                                <td class="product-thumbnail">
                                  <a href="https://fiorello.qodeinteractive.com/product/foxglove-flower/">
                                    <img
                                      width="300"
                                      height="400"
                                      src={item.image}
                                      class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                      alt="k"
                                    />
                                  </a>{" "}
                                </td>

                                <td class="product-name" data-title="Product">
                                  <a href="https://fiorello.qodeinteractive.com/product/foxglove-flower/">
                                    {item.name}
                                  </a>{" "}
                                </td>

                                <td class="product-price" data-title="Price">
                                  <span class="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span class="woocommerce-Price-currencySymbol">
                                        ₺
                                      </span>
                                      {item.price}
                                    </bdi>
                                  </span>{" "}
                                </td>

                                <td
                                  class="product-quantity"
                                  data-title="Quantity"
                                >
                                  <div class="mkdf-quantity-buttons quantity">
                                    <label
                                      class="screen-reader-text"
                                      for="quantity_61fefe7629365"
                                    >
                                      Miktar
                                    </label>
                                    <span
                                      class="mkdf-quantity-label"
                                      for="quantity_61fefe7629365"
                                    >
                                      Miktar
                                    </span>
                                    <span
                                      class="mkdf-quantity-minus arrow_triangle-left"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (quantity != 1) {
                                          setQuantity(quantity - 1);
                                          RemoveFromCart(item);
                                        }
                                      }}
                                    ></span>
                                    <input
                                      type="text"
                                      id="quantity_61fefe7629365"
                                      class="input-text qty text mkdf-quantity-input"
                                      data-step="1"
                                      data-min="0"
                                      data-max=""
                                      value={item.quantity}
                                      title="Qty"
                                      size="4"
                                      placeholder=""
                                      inputmode="numeric"
                                    />
                                    <span
                                      class="mkdf-quantity-plus arrow_triangle-right"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setQuantity(quantity + 1);
                                        AddToCart(item);
                                      }}
                                    ></span>
                                  </div>
                                </td>

                                <td
                                  class="product-subtotal"
                                  data-title="Subtotal"
                                >
                                  <span class="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span class="woocommerce-Price-currencySymbol">
                                        ₺
                                      </span>
                                      {item.price * item.quantity}
                                    </bdi>
                                  </span>{" "}
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td colspan="6" class="actions">
                                <div class="coupon">
                                  <label for="coupon_code">Coupon:</label>{" "}
                                  <input
                                    type="text"
                                    name="coupon_code"
                                    class="input-text"
                                    id="coupon_code"
                                    value=""
                                    placeholder="Kupon Kodu"
                                  />{" "}
                                  <button
                                    type="submit"
                                    class="button"
                                    name="apply_coupon"
                                    value="Apply coupon"
                                  >
                                    Ekle
                                  </button>
                                </div>
                                <input
                                  type="hidden"
                                  id="woocommerce-cart-nonce"
                                  name="woocommerce-cart-nonce"
                                  value="ac945442aa"
                                />
                                <input
                                  type="hidden"
                                  name="_wp_http_referer"
                                  value="/cart/"
                                />{" "}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>

                      <div class="cart-collaterals">
                        <div class="cart_totals ">
                          <table
                            cellspacing="0"
                            class="shop_table shop_table_responsive"
                          >
                            <tbody>
                              <tr class="order-total">
                                <th>Toplam</th>
                                <td data-title="Total">
                                  <strong>
                                    <span class="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span class="woocommerce-Price-currencySymbol">
                                          ₺
                                        </span>
                                        {calculateTotal()}
                                      </bdi>
                                    </span>
                                  </strong>{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="wc-proceed-to-checkout">
                            <a
                              href="/odeme"
                              class="checkout-button button alt wc-forward"
                            >
                              Ödemeye Geç
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;

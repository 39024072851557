import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase, { auth, firestore } from "../../utils/firebase";
import "./login.css";

const Login = () => {
  const username = useRef();
  const password = useRef();

  const navigate = useNavigate();

  function show() {
    var p = document.getElementById("pwd");
    p.setAttribute("type", "text");
  }

  function hide() {
    var p = document.getElementById("pwd");
    p.setAttribute("type", "password");
  }

  useEffect(() => {
    var pwShown = 0;

    document.getElementById("eye").addEventListener(
      "click",
      function () {
        if (pwShown == 0) {
          pwShown = 1;
          show();
        } else {
          pwShown = 0;
          hide();
        }
      },
      false
    );
  }, []);

  const login = (e) => {
    e.preventDefault();
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(
          username.current.value + "@dba.com",
          password.current.value
        )
        .then((userCredential) => {
          // Signed in
          navigate("/admin");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error);
        });
    } catch (err) {
      alert("Hatalı Giriş");
    }
  };
  return (
    <div className="login overlay">
      <form>
        <div className="con">
          <header className="head-form">
            <h2>Giriş Yap</h2>
            <p>DBA Teknoloji Yazılım</p>
          </header>
          <br />
          <div className="field-set">
            <span className="input-item">
              <i className="fa fa-user-circle"></i>
            </span>
            <input
              className="form-input"
              id="txt-input"
              type="text"
              placeholder="Kullanıcı Adı"
              required
              ref={username}
            />

            <br />

            <span className="input-item">
              <i className="fa fa-key"></i>
            </span>
            <input
              className="form-input"
              type="password"
              placeholder="Şifre"
              id="pwd"
              name="password"
              required
              ref={password}
            />

            <span>
              <i
                className="fa fa-eye"
                aria-hidden="true"
                type="button"
                id="eye"
              ></i>
            </span>

            <br />
            <button className="log-in" onClick={login}>
              Giriş Yap
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar";
import Menu from "./Menu/Menu";
import GelenSiparisler from "./Siparisler/GelenSiparisler";
import Siparisler from "./Gecmis/Siparisler";
import Login from "./Login";
import { auth, firestore } from "../../utils/firebase";

const Panel = () => {
  const [active, setActive] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    firestore
      .collection("atakum-cicekci")
      .doc("info")
      .get()
      .then((doc) => {
        setActive(doc.data().active);
      });
  }, []);
  const handleCheckbox = () => {
    firestore
      .collection("atakum-cicekci")
      .doc("info")
      .set({ active: !active })
      .then(() => {
        localStorage.setItem("active", !active);
        setActive(!active);
      });
  };
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/admin/giris");
      }
    });
  }, []);
  return (
    <>
      <Sidebar active={active} handleCheckbox={handleCheckbox} />

      <Routes>
        <Route path="/menu" element={<Menu />} />
        <Route
          path="/siparisler"
          element={<GelenSiparisler active={active} />}
        />
        <Route path="/gecmis" element={<Siparisler />} />
      </Routes>
    </>
  );
};

export default Panel;

/*

 <Route path="/gecmis">
          <GelenSiparisler />
        </Route>
*/

/* eslint-disable no-unused-expressions */
import React from "react";
import firebase, { firestore, auth } from "../../../utils/firebase";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Siparisler.css";
import { v4 as uuid } from "uuid";
import Order from "./Order";

const GelenSiparisler = ({ active }) => {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    firestore
      .collection(`atakum-cicekci`)
      .doc("orders")
      .onSnapshot((doc) => {
        if (doc.exists) {
          let order = Object.entries(doc.data());
          setOrders(order);
        }
      });
  }, []);

  const approveOrder = (id) => {
    let newOrders = orders;
    let table;

    orders?.forEach((o, i, newOrders) => {
      if (o[0] == id) {
        newOrders[i][1].status = 1;
      }
    });

    firestore
      .collection(`atakum-cicekci`)
      .doc("orders")
      .set(Object.fromEntries(newOrders));
  };
  const cancelOrder = (id) => {
    let email;
    orders?.forEach((o, i) => {
      if (o[0] == id) {
        email = orders[i][1].checkoutemail;
      }
    });

    let newOrders = Object.fromEntries(orders.filter((o) => o[0] != id));
    firestore
      .collection(`atakum-cicekci`)
      .doc("orders")
      .set(newOrders)
      .then(() => {
        fetch(
          `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?
        email=${email}&type=2`
        );
      });
  };
  const deliverOrder = (id, type) => {
    let newOrders = orders;
    let email;

    orders?.forEach((o, i, newOrders) => {
      if (o[0] == id) {
        newOrders[i][1].status = 2;
        email = newOrders[i][1].checkoutemail;
      }
    });

    firestore
      .collection(`atakum-cicekci`)
      .doc("orders")
      .set(Object.fromEntries(newOrders))
      .then(() => {
        if (type == "deliver")
          fetch(
            `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${email}&type=1`
          );
      });
  };
  const notActive = (id) => {
    let newOrders = orders;
    let email;

    orders?.forEach((o, i, newOrders) => {
      if (o[0] == id) {
        newOrders[i][1].status = 3;
        email = newOrders[i][1].checkoutemail;
      }
    });

    firestore
      .collection(`atakum-cicekci`)
      .doc("orders")
      .set(Object.fromEntries(newOrders))
      .then(() => {
        try {
          fetch(
            `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${email}&type=3`
          );
        } catch {}
      });
  };

  console.log(active);
  return (
    <>
      <div className="row" style={{ marginLeft: "17%", height: "50%" }}>
        {active != (null || undefined) &&
          orders &&
          orders
            .filter((o) => o[1].status == 0)
            .map((order) => {
              return (
                <Order
                  order={order[1]}
                  approveOrder={approveOrder}
                  cancelOrder={cancelOrder}
                  notActive={notActive}
                  active={active}
                />
              );
            })}
      </div>
      <div className="row" style={{ marginLeft: "17%", height: "50%" }}>
        {active != (null || undefined) &&
          orders &&
          orders
            .filter((o) => o[1].status == 1)
            .map((order) => {
              return (
                <Order
                  order={order[1]}
                  approveOrder={approveOrder}
                  cancelOrder={cancelOrder}
                  deliverOrder={deliverOrder}
                  active={active}
                />
              );
            })}
      </div>
    </>
  );
};

export default GelenSiparisler;

/*
 {garson && (
        <Garson masa={garson[0]} visible={true} cafe={cafe} garson={garson} />


        
      )}
*/

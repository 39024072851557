import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Category = ({ AddToCart }) => {
    const { category } = useLocation().state;
    return (
        <div className="mkdf-content">
            <div className="mkdf-content-inner">
                <div className="mkdf-wrapper">
                    <div className="mkdf-wrapper-inner">
                        <a id="mkdf-back-to-top" href="#">
                            <span className="mkdf-icon-stack">
                                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>{" "}
                                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>
                            </span>
                        </a>

                        <div className="mkdf-content">
                            <div className="mkdf-content-inner">
                                <div className="mkdf-full-width">
                                    <div className="mkdf-full-width-inner">
                                        <div className="mkdf-grid-row">
                                            <div className="mkdf-page-content-holder mkdf-grid-col-12">
                                                <div className="mkdf-row-grid-section-wrapper ">
                                                    <div className="mkdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1526044222558">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <h1>{category?.name}</h1>
                                                                        <div
                                                                            className="mkdf-pl-holder mkdf-standard-layout mkdf-medium-space mkdf-four-columns mkdf-info-below-image "
                                                                            data-type="standard"
                                                                            data-info-position="info-below-image"
                                                                            data-number-of-posts="8"
                                                                            data-number-of-columns="4"
                                                                            data-space-between-items="medium"
                                                                            data-show-ordering-filter="yes"
                                                                            data-orderby="date"
                                                                            data-order="ASC"
                                                                            data-show-category-filter="yes"
                                                                            data-show-all-item-in-filter="yes"
                                                                            data-taxonomy-to-display="category"
                                                                            data-display-title="yes"
                                                                            data-title-tag="h6"
                                                                            data-display-category="no"
                                                                            data-display-rating="no"
                                                                            data-display-price="yes"
                                                                            data-display-button="yes"
                                                                            data-button-skin="default"
                                                                            data-class-name="pli"
                                                                            data-layout-classes="mkdf-pli-hover-overlay"
                                                                        >

                                                                            <div className="mkdf-pl-outer mkdf-outer-space" style={{ marginTop: 30 }}>
                                                                                {category?.items.map((item) => (
                                                                                    <div className="mkdf-pli mkdf-item-space ">
                                                                                        <div className="mkdf-pli-inner">
                                                                                            <div className="mkdf-pli-image">
                                                                                                <img
                                                                                                    width="620"
                                                                                                    height="800"
                                                                                                    src={item.image}
                                                                                                    className="attachment-woocommerce_single size-woocommerce_single wp-post-image"
                                                                                                    alt="l"
                                                                                                />{" "}
                                                                                            </div>
                                                                                            <Link
                                                                                                className="mkdf-pli-link"
                                                                                                itemprop="url"
                                                                                                to={"/urun"}
                                                                                                state={{ item }}
                                                                                                title={item.name}
                                                                                            ></Link>
                                                                                        </div>
                                                                                        <div className="mkdf-pli-text-wrapper">
                                                                                            <h6
                                                                                                itemprop="name"
                                                                                                className="entry-title mkdf-pli-title"
                                                                                            >
                                                                                                <Link
                                                                                                    className="mkdf-pli-link"
                                                                                                    itemprop="url"
                                                                                                    to={"/urun"}
                                                                                                    state={{ item }}
                                                                                                    title={item.name}
                                                                                                >
                                                                                                    {item.name}
                                                                                                </Link>
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="mkdf-prl-loading">
                                                                                <span className="mkdf-prl-loading-msg">
                                                                                    Yükleniyor...
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category
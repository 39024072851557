import React, { useEffect, useState } from "react";
import "./order.css";
import { firestore } from "../../../utils/firebase";
import { confirmAlert } from "react-confirm-alert";
import Audio from "../../../assets/notification.mp3";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

const Order = ({
  order,
  approveOrder,
  cancelOrder,
  deliverOrder,
  notActive,
  active,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (active != null && active == false) {
      notActive(order.id);
    } else if (order.status == 0) {
      document.getElementsByClassName("audio-element")[0].play();
    }
  }, []);

  const printOrder = () => {
    navigate(`/print/${order.id}`);
  };
  const calculateTotal = () => {
    let items = [...order.cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total.toFixed(2);
  };
  const calculateDiscount = () => {
    let total = calculateTotal();
    if (total > 50) total -= 5;
    if (order.deliveryType == "takeaway") total *= 0.9;
    return total.toFixed(2);
  };
  return (
    <div
      className="col-sm-6 col-lg-3"
      style={{ display: !active ? "none" : "" }}
    >
      <audio className="audio-element">
        <source src={Audio}></source>
      </audio>
      <div
        className="card text-center"
        style={{ minHeight: 300, color: "black" }}
      >
        <div
          className="card-body"
          style={{ background: order?.status == "1" ? "#eeeeee" : "" }}
        >
          <div>
            {order?.cart?.map((o) => {
              return (
                <>
                  <h5 className="card-title" style={{ textAlign: "left" }}>
                    {o.quantity} x {o.name}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {o.quantity * o.price} TL
                    </span>
                  </h5>

                  {o.mealNote && <h5 className="card-title"> {o.mealNote} </h5>}
                </>
              );
            })}
            Toplam Tutar:
            <p className="card-text ">
              {" "}
              <span
                style={{
                  textDecoration:
                    order?.deliveryType == "takeaway" ? "line-through" : "",
                  fontWeight: "bold",
                }}
              >
                {calculateTotal()} TL
              </span>{" "}
            </p>
          </div>
          <div style={{ fontWeight: 600 }}>
            <p className="card-text">{order?.name}</p>
            <p className="card-text">{order?.phone}</p>
            <p className="card-text">{order?.address}</p>

            {order?.note && (
              <h5 className="card-title" style={{ fontWeight: "bold" }}>
                Not: {order.note}{" "}
              </h5>
            )}
          </div>

          <div className="row">
            {order?.status == 0 && (
              <>
                <div className="col">
                  <a
                    href="#"
                    className="btn btn-dark"
                    onClick={() => {
                      confirmAlert({
                        message: "Onaylıyor musunuz?",
                        buttons: [
                          {
                            label: "Evet",
                            onClick: () => {
                              approveOrder(order.id);
                            },
                          },
                          {
                            label: "Hayır",
                          },
                        ],
                      });
                    }}
                  >
                    Onayla
                  </a>
                </div>
              </>
            )}
          </div>
          {order.status == 0 ? (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-danger"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          cancelOrder(order.id);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                İptal Et
              </div>
            </div>
          ) : order.deliveryType == "delivery" ? (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-secondary"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          deliverOrder(order.id);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                Yola Çıkar
              </div>
            </div>
          ) : (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-secondary"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          deliverOrder(order.id, order.deliveryType);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                Teslim Edildi
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;

import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Client/Footer";
import Header from "../../components/Client/Header";

const Index = ({ menu, categories, cart, AddToCart, RemoveFromCart }) => {
  const [selected, Select] = useState(categories[0]);
  const [items, setItems] = useState([]);
  const [openCategories, setOpenCategories] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    if (window.innerWidth <= 800) setOpenCategories(true);
    else setOpenCategories(false);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add(
      "home",
      "page-template",
      "page-template-full-width",
      "page-template-full-width-php",
      "page",
      "page-id-189",
      "theme-fiorello",
      "fiorello-core-1.2",
      "woocommerce-no-js",
      "fiorello-ver-1.5",
      "mkdf-grid-1300",
      "mkdf-content-background-full-image",
      "mkdf-sticky-header-on-scroll-down-up",
      "mkdf-dropdown-animate-height",
      "mkdf-header-standard",
      "mkdf-menu-area-shadow-disable",
      "mkdf-menu-area-in-grid-shadow-disable",
      "mkdf-menu-area-border-disable",
      "mkdf-menu-area-in-grid-border-disable",
      "mkdf-logo-area-border-disable",
      "mkdf-woocommerce-columns-3",
      "mkdf-woo-medium-space",
      "mkdf-woo-pl-info-below-image",
      "mkdf-woo-single-thumb-on-left-side",
      "mkdf-woo-single-has-pretty-photo",
      "mkdf-default-mobile-header",
      "mkdf-sticky-up-mobile-header",
      "mkdf-slide-from-header-bottom",
      "wpb-js-composer",
      "js-comp-ver-6.6.0",
      "vc_responsive"
    );
    document.body.setAttribute("itemscope", "");
    document.body.setAttribute("itemtype", "http://schema.org/WebPage");
  }, []);

  useEffect(() => {
    setItems(menu.filter((m) => m.name == selected.name)[0]);
  }, [selected]);

  useEffect(() => {
    Select(categories[0]);
  }, [categories]);

  return (
    <div className="mkdf-content">
      <div className="mkdf-content-inner">
        <div className="mkdf-wrapper">
          <div className="mkdf-wrapper-inner">
            <a id="mkdf-back-to-top" href="#">
              <span className="mkdf-icon-stack">
                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>{" "}
                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>
              </span>
            </a>
            <div className="mkdf-content">
              <div className="mkdf-content-inner">
                <div className="mkdf-full-width">
                  <div className="mkdf-full-width-inner">
                    <div className="mkdf-grid-row">
                      <div className="mkdf-page-content-holder mkdf-grid-col-12">
                        <div className="vc_row wpb_row vc_row-fluid">
                          <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_revslider_element wpb_content_element">
                                  <p className="rs-p-wp-fix"></p>
                                  <rs-module-wrap
                                    id="rev_slider_3_1_wrapper"
                                    data-source="gallery"
                                    style={{
                                      background: "transparent",
                                      padding: 0,
                                      margin: "0px auto",
                                      margintop: 0,
                                      marginbottom: 0,
                                    }}
                                  >
                                    <rs-module
                                      id="rev_slider_3_1"
                                      style={{}}
                                      data-version="6.4.11"
                                    >
                                      <rs-slides>
                                        <rs-slide
                                          data-key="rs-5"
                                          data-title="Slide"
                                          data-thumb="//fiorello.qodeinteractive.com/wp-content/uploads/2018/05/h3-slider-background-100x50.jpg"
                                          data-in="o:0;"
                                          data-out="a:false;"
                                          data-p1="01"
                                        >
                                          <img
                                            src="wp-content/uploads/2018/05/h3-slider-background.jpg"
                                            title="Main Home"
                                            className="rev-slidebg tp-rs-img"
                                            data-bg="p:center right;"
                                            data-panzoom="d:10000;ss:110;se:100;"
                                            data-no-retina
                                          />
                                        </rs-slide>
                                        <rs-slide
                                          data-key="rs-11"
                                          data-title="Slide"
                                          data-thumb="//fiorello.qodeinteractive.com/wp-content/uploads/2018/05/h3-slider-background-2-100x50.jpg"
                                          data-in="o:0;"
                                          data-out="a:false;"
                                          data-p1="02"
                                        >
                                          <img
                                            src="wp-content/uploads/2018/05/h3-slider-background-2.jpg"
                                            title="Main Home"
                                            className="rev-slidebg tp-rs-img"
                                            data-bg="p:center bottom;"
                                            data-panzoom="d:10000;ss:100;se:115;"
                                            data-no-retina
                                          />
                                        </rs-slide>
                                        <rs-slide
                                          data-key="rs-13"
                                          data-title="Slide"
                                          data-thumb="//fiorello.qodeinteractive.com/wp-content/uploads/2018/05/h3-slider-background-3-100x50.jpg"
                                          data-in="o:0;"
                                          data-out="a:false;"
                                          data-p1="03"
                                        >
                                          <img
                                            src="wp-content/uploads/2018/05/h3-slider-background-3.jpg"
                                            title="Main Home"
                                            className="rev-slidebg tp-rs-img"
                                            data-panzoom="d:10000;ss:100;se:110;"
                                            data-no-retina
                                          />
                                        </rs-slide>
                                      </rs-slides>
                                      <rs-static-layers>
                                        <rs-layer
                                          id="slider-3-slide-3-layer-17"
                                          className="rs-layer-static"
                                          data-type="text"
                                          data-color="#000000"
                                          data-rsp_ch="on"
                                          data-xy="y:m;yo:-117px,-99px,-98px,-35px;"
                                          data-text="w:normal;s:60,41,41,30;l:70,51,51,40;a:inherit;"
                                          data-dim="w:506px,347px,339px,281px;h:auto,103px,143px,97px;"
                                          data-onslides="s:1;"
                                          data-frame_0="tp:600;"
                                          data-frame_1="tp:600;st:400;sp:1300;sR:400;"
                                          data-frame_999="o:0;tp:600;st:w;sR:2300;"
                                          style={{
                                            zIndex: 5,
                                            fontFamily: "Playfair Display",
                                          }}
                                        >
                                          Atakum{" "}
                                          <span style={{ color: "red" }}>
                                            Çiçekçilik
                                          </span>{" "}
                                          Royal Flowers
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-3-slide-3-layer-18"
                                          className="rs-layer-static"
                                          data-type="text"
                                          data-color="#6d6a6a"
                                          data-rsp_ch="on"
                                          data-xy="xo:0,0,0,-361px;y:m;yo:26px,16px,12px,7px;"
                                          data-text="w:normal;s:17;l:29;a:inherit;"
                                          data-dim="w:497px,429px,322px,291px;h:88px,89px,auto,117px;"
                                          data-vbility="t,t,t,f"
                                          data-onslides="s:1;"
                                          data-frame_0="tp:600;"
                                          data-frame_1="tp:600;st:550;sp:1300;sR:550;"
                                          data-frame_999="o:0;tp:600;st:w;sR:2150;"
                                          style={{
                                            zIndex: 6,
                                            fontgamily: "Lora",
                                          }}
                                        >
                                          Çiçeklerin kalıcı anılar yaratmak için
                                          ilham kaynağımız olduğu yer. Durum ne
                                          olursa olsun, çiçeklerimiz onu özel
                                          yapacak.
                                        </rs-layer>
                                        <rs-layer
                                          id="slider-3-slide-3-layer-19"
                                          className="rs-layer-static"
                                          data-type="image"
                                          data-rsp_ch="on"
                                          data-xy="y:m;yo:137px,113px,129px,49px;"
                                          data-text="l:22;a:inherit;"
                                          data-dim="w:['197px','197px','197px','197px'];h:['56px','56px','56px','56px'];"
                                          data-onslides="s:1;"
                                          data-frame_0="tp:600;"
                                          data-frame_1="tp:600;st:700;sp:1300;sR:700;"
                                          data-frame_999="o:0;tp:600;st:w;sR:2000;"
                                          style={{ zIndex: 7 }}
                                        >
                                          <img
                                            src="wp-content/uploads/2018/04/h2-sign-img.png"
                                            alt="s"
                                            className="tp-rs-img"
                                            width="197"
                                            height="56"
                                            data-no-retina
                                          />
                                        </rs-layer>
                                      </rs-static-layers>
                                    </rs-module>
                                  </rs-module-wrap>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mkdf-row-grid-section-wrapper ">
                          <div className="mkdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1526044222558">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div
                                      className="mkdf-pl-holder mkdf-standard-layout mkdf-medium-space mkdf-four-columns mkdf-info-below-image "
                                      data-type="standard"
                                      data-info-position="info-below-image"
                                      data-number-of-posts="8"
                                      data-number-of-columns="4"
                                      data-space-between-items="medium"
                                      data-show-ordering-filter="yes"
                                      data-orderby="date"
                                      data-order="ASC"
                                      data-show-category-filter="yes"
                                      data-show-all-item-in-filter="yes"
                                      data-taxonomy-to-display="category"
                                      data-display-title="yes"
                                      data-title-tag="h6"
                                      data-display-category="no"
                                      data-display-rating="no"
                                      data-display-price="yes"
                                      data-display-button="yes"
                                      data-button-skin="default"
                                      data-class-name="pli"
                                      data-layout-classes="mkdf-pli-hover-overlay"
                                    >
                                      {/* <div className="mkdf-pl-cat-order-holder clearfix">
                                        <div className="mkdf-pl-ordering-outer">
                                          <div className="mkdf-pl-ordering-holder">
                                            <h6 className="mkdf-pl-ordering-filter-title">
                                              Filtre
                                            </h6>
                                            <div className="mkdf-pl-ordering">
                                              <div className="mkdf-pl-ordering-inner">
                                                <h5 className="mkdf-pl-ordering-title">
                                                  Sırala
                                                </h5>
                                                <ul>
                                                  <li>
                                                    <a
                                                      className="mkdf-no-smooth-transitions"
                                                      data-ordering="menu_order"
                                                      href="#"
                                                    >
                                                      Default
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="mkdf-no-smooth-transitions"
                                                      data-ordering="popularity"
                                                      href="#"
                                                    >
                                                      Popülerler
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="mkdf-no-smooth-transitions"
                                                      data-ordering="newness"
                                                      href="#"
                                                    >
                                                      En Yeniler
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="mkdf-no-smooth-transitions"
                                                      data-ordering="price"
                                                      href="#"
                                                    >
                                                      Artan Fiyat
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="mkdf-no-smooth-transitions"
                                                      data-ordering="price-desc"
                                                      href="#"
                                                    >
                                                      Azalan Fiyat
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className="mkdf-pl-ordering-inner">
                                                <h5 className="mkdf-pl-ordering-title">
                                                  Fiyat Aralığı
                                                </h5>
                                                <ul className="mkdf-pl-ordering-price">
                                                  <li>
                                                    <a
                                                      data-minPrice=""
                                                      data-maxPrice=""
                                                      href="#"
                                                    >
                                                      Hepsi
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-minPrice="0"
                                                      data-maxPrice="10"
                                                      href="#"
                                                    >
                                                      &#36;0-&#36;10
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-minPrice="10"
                                                      data-maxPrice="20"
                                                      href="#"
                                                    >
                                                      &#36;10-&#36;20
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-minPrice="20"
                                                      data-maxPrice="30"
                                                      href="#"
                                                    >
                                                      &#36;20-&#36;30
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-minPrice="30"
                                                      data-maxPrice="40"
                                                      href="#"
                                                    >
                                                      &#36;30-&#36;40
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      data-minPrice="40"
                                                      data-maxPrice="100000000000"
                                                      href="#"
                                                    >
                                                      40&#36;+
                                                    </a>
                                                  </li>{" "}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      <h1>Kategoriler</h1>
                                      <div className="mkdf-pl-outer mkdf-outer-space" style={{ marginTop: 20 }}>
                                        {categories?.map((category) => (
                                          <div className="mkdf-pli mkdf-item-space ">
                                            <div className="mkdf-pli-inner">
                                              <div className="mkdf-pli-image">
                                                <img
                                                  width="620"
                                                  height="800"
                                                  src={category.image}
                                                  className="attachment-woocommerce_single size-woocommerce_single wp-post-image"
                                                  alt="l"
                                                />{" "}
                                              </div>
                                              <Link
                                                className="mkdf-pli-link"
                                                itemprop="url"
                                                to={"/kategori"}
                                                state={{ category }}
                                                title={category.name}
                                              ></Link>
                                            </div>
                                            <div className="mkdf-pli-text-wrapper">
                                              <h6
                                                itemprop="name"
                                                className="entry-title mkdf-pli-title"
                                              >
                                                <Link
                                                  className="mkdf-pli-link"
                                                  itemprop="url"
                                                  to={"/kategori"}
                                                  state={{ category }}
                                                  title={category.name}
                                                >
                                                  {category.name}
                                                </Link>
                                              </h6>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <div className="mkdf-prl-loading">
                                        <span className="mkdf-prl-loading-msg">
                                          Yükleniyor...
                                        </span>
                                      </div>
                                      {/* <div className="mkdf-pl-outer mkdf-outer-space">
                                        {items?.Meals?.map((item) => (
                                          <div className="mkdf-pli mkdf-item-space ">
                                            <div className="mkdf-pli-inner">
                                              <div className="mkdf-pli-image">
                                                <img
                                                  width="620"
                                                  height="800"
                                                  src={item.image}
                                                  className="attachment-woocommerce_single size-woocommerce_single wp-post-image"
                                                  alt="l"
                                                />{" "}
                                              </div>
                                              <Link
                                                className="mkdf-pli-link"
                                                itemprop="url"
                                                to={"/urun"}
                                                state={{ item }}
                                                title="Majesty Palm"
                                              ></Link>
                                            </div>
                                            <div className="mkdf-pli-text-wrapper">
                                              <h6
                                                itemprop="name"
                                                className="entry-title mkdf-pli-title"
                                              >
                                                <a
                                                  itemprop="url"
                                                  href="product/majesty-palm/index.html"
                                                >
                                                  {item.name}
                                                </a>
                                              </h6>
                                              <div className="mkdf-pli-add-to-cart mkdf-default-skin">
                                                <a
                                                  rel="nofollow"
                                                  onClick={() => {
                                                    AddToCart(item);
                                                  }}
                                                  style={{
                                                    fontSize: 14,
                                                    cursor: "pointer",
                                                  }}
                                                  data-quantity="1"
                                                  data-product_id="365"
                                                  data-product_sku="7555"
                                                  className="button add_to_cart_button ajax_add_to_cart mkdf-button"
                                                >
                                                  Sepete Ekle
                                                </a>{" "}
                                              </div>
                                              <div className="mkdf-pli-price">
                                                <span
                                                  className="woocommerce-Price-amount amount"
                                                  style={{
                                                    color: "black",
                                                    fontSize: 17,
                                                  }}
                                                >
                                                  <span className="woocommerce-Price-currencySymbol">
                                                    ₺
                                                  </span>
                                                  {item.price}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="mkdf-row-grid-section-wrapper "
                          style={{ backgroundColor: "#f7f7f7" }}
                        >
                          <div className="mkdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1526286525706">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="mkdf-elements-holder   mkdf-two-columns  mkdf-responsive-mode-1024 ">
                                      <div
                                        className="mkdf-eh-item  mkdf-vertical-alignment-top    "
                                        data-item-class="mkdf-eh-custom-4164"
                                      >
                                        <div className="mkdf-eh-item-background-image"></div>
                                        <div className="mkdf-eh-item-inner">
                                          <div
                                            className="mkdf-eh-item-content mkdf-eh-custom-4164"
                                            style={{ padding: "0 0 0 0" }}
                                          >
                                            <div className="mkdf-video-button-holder  mkdf-vb-has-img">
                                              <div className="mkdf-video-button-image">
                                                <img
                                                  width={800}
                                                  height={518}
                                                  src="wp-content/uploads/2018/04/h3-video-img.jpg"
                                                  className="attachment-full size-full"
                                                  alt="a"
                                                  srcset="https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h3-video-img.jpg 800w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h3-video-img-300x194.jpg 300w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h3-video-img-768x497.jpg 768w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h3-video-img-225x146.jpg 225w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h3-video-img-75x50.jpg 75w"
                                                  sizes="(max-width: 800px) 100vw, 800px"
                                                />{" "}
                                              </div>
                                              <a
                                                className="mkdf-video-button-play"
                                                href="https://www.youtube.com/watch?time_continue=1&amp;v=K-0cjGCNYfs"
                                                target="_self"
                                                data-rel="prettyPhoto[video_button_pretty_photo_144]"
                                              >
                                                <span className="mkdf-video-button-play-inner">
                                                  <span className="mkdf-video-button-outer">
                                                    <span className="arrow_triangle-right"></span>
                                                  </span>
                                                </span>
                                              </a>
                                            </div>{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="mkdf-eh-item  mkdf-vertical-alignment-top    "
                                        data-item-class="mkdf-eh-custom-7712"
                                        data-1024-1366="4px 6% 0px 70px"
                                        data-768-1024="59px 37% 0px 0px"
                                        data-680-768="59px 24% 0px 0px"
                                        data-680="59px 0% 0px 0px"
                                      >
                                        <div className="mkdf-eh-item-background-image"></div>
                                        <div className="mkdf-eh-item-inner">
                                          <div
                                            className="mkdf-eh-item-content mkdf-eh-custom-7712"
                                            style={{
                                              padding: "31px 14% 0px 70px",
                                            }}
                                          >
                                            <div className="mkdf-section-title-holder  mkdf-st-disable-title-break mkdf-st-subtitle-pos-under">
                                              <div className="mkdf-st-inner">
                                                <h2 className="mkdf-st-title">
                                                  <span className="mkdf-st-title-highlight">
                                                    Sevgilinize{" "}
                                                  </span>
                                                  Sürpriz Yapın!
                                                  <br /> Let us arrange a smile.{" "}
                                                </h2>
                                                <p
                                                  className="mkdf-st-text"
                                                  style={{
                                                    fontSize: 17,
                                                    lineHeight: 3,
                                                    marginTop: 27,
                                                  }}
                                                >
                                                  Çiçeklerin kalıcı anılar
                                                  yaratmak için ilham kaynağımız
                                                  olduğu yer.
                                                  <br />
                                                  Durum ne olursa olsun...{" "}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              className="mkdf-icon-list-holder "
                                              style={{ marginBottom: 19 }}
                                            >
                                              <div className="mkdf-il-icon-holder">
                                                <img
                                                  width="19"
                                                  height="18"
                                                  src="wp-content/uploads/2018/04/h1-custom-icon.png"
                                                  className="attachment-full size-full"
                                                  alt="s"
                                                />{" "}
                                              </div>
                                              <p
                                                className="mkdf-il-text"
                                                style={{ fontSize: 17 }}
                                              >
                                                Sizin için özenle seçilmiştir.
                                              </p>
                                            </div>
                                            <div
                                              className="mkdf-icon-list-holder "
                                              style={{ marginBottom: 19 }}
                                            >
                                              <div className="mkdf-il-icon-holder">
                                                <img
                                                  width="19"
                                                  height="18"
                                                  src="wp-content/uploads/2018/04/h1-custom-icon.png"
                                                  className="attachment-full size-full"
                                                  alt="s"
                                                />{" "}
                                              </div>
                                              <p
                                                className="mkdf-il-text"
                                                style={{ fontSize: 17 }}
                                              >
                                                Eşsiz çiçek aranjmanları.
                                              </p>
                                            </div>
                                            <div
                                              className="mkdf-icon-list-holder "
                                              style={{ marginBottom: 19 }}
                                            >
                                              <div className="mkdf-il-icon-holder">
                                                <img
                                                  width="19"
                                                  height="18"
                                                  src="wp-content/uploads/2018/04/h1-custom-icon.png"
                                                  className="attachment-full size-full"
                                                  alt="s"
                                                />{" "}
                                              </div>
                                              <p
                                                className="mkdf-il-text"
                                                style={{ fontSize: 17 }}
                                              >
                                                Önemsediğini söylemenin en iyi
                                                yolu.
                                              </p>
                                            </div>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mkdf-row-grid-section-wrapper ">
                          <div className="mkdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1526289670330"></div>
                          </div>
                        </div>
                        <div
                          className="mkdf-row-grid-section-wrapper mkdf-parallax-row-holder mkdf-content-aligment-center"
                          data-parallax-bg-image="https://fiorello.qodeinteractive.com/wp-content/uploads/2018/05/h3-background-img.jpg"
                          data-parallax-bg-speed="1"
                        >
                          <div className="mkdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1526628616225">
                              <div className="wpb_column vc_column_container vc_col-sm-10 vc_col-lg-offset-3 vc_col-lg-6 vc_col-md-offset-2 vc_col-md-8 vc_col-sm-offset-1">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="mkdf-section-title-holder   mkdf-st-subtitle-pos-under">
                                      <div className="mkdf-st-inner">
                                        <h3
                                          className="mkdf-st-title"
                                          style={{ color: "#ffffff" }}
                                        >
                                          Bizden Haberdar Olmak için Abone Olun!{" "}
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="vc_empty_space"
                                      style={{ height: 12 }}
                                    >
                                      <span className="vc_empty_space_inner"></span>
                                    </div>
                                    <div
                                      role="form"
                                      className="wpcf7"
                                      id="wpcf7-f133-p189-o1"
                                      lang="en-US"
                                      dir="ltr"
                                    >
                                      <div className="screen-reader-response">
                                        <p
                                          role="status"
                                          aria-live="polite"
                                          aria-atomic="true"
                                        ></p>{" "}
                                        <ul></ul>
                                      </div>
                                      <form
                                        action="https://fiorello.qodeinteractive.com/#wpcf7-f133-p189-o1"
                                        method="post"
                                        className="wpcf7-form init cf7_custom_style_2"
                                        novalidate="novalidate"
                                        data-status="init"
                                      >
                                        <div style={{ display: "none" }}>
                                          <input
                                            type="hidden"
                                            name="_wpcf7"
                                            value="133"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_version"
                                            value="5.5.3"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_locale"
                                            value="en_US"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_unit_tag"
                                            value="wpcf7-f133-p189-o1"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_container_post"
                                            value="189"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_posted_data_hash"
                                            value=""
                                          />
                                        </div>
                                        <div className="mkdf-two-columns-form-without-space">
                                          <div className="mkdf-column-left">
                                            <span className="wpcf7-form-control-wrap your-email">
                                              <input
                                                type="email"
                                                name="your-email"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                aria-required="true"
                                                aria-invalid="false"
                                                placeholder="Email Adresi"
                                              />
                                            </span>
                                          </div>
                                          <div className="mkdf-column-right">
                                            <input
                                              type="submit"
                                              value="Gönder"
                                              className="wpcf7-form-control has-spinner wpcf7-submit"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="wpcf7-response-output"
                                          aria-hidden="true"
                                        ></div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="mkdf-row-grid-section-wrapper mkdf-content-aligment-center"
                          style={{ backgroundColor: "#f8f8f8" }}
                        ></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkdf-content-bottom">
                  <div className="mkdf-content-bottom-inner mkdf-full-width">
                    <div className="widget widget_mkdf_instagram_widget"></div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Product = ({ AddToCart }) => {
  const { item } = useLocation().state;
  useEffect(() => {
    document.body.classList.add(
      "product-template-default",
      "single",
      "single-product",
      "postid-365",
      "theme-fiorello",
      "fiorello-core-1.2",
      "woocommerce",
      "woocommerce-page",
      "woocommerce-no-js",
      "fiorello-ver-1.5",
      "mkdf-grid-1300",
      "mkdf-content-background-full-image",
      "mkdf-sticky-header-on-scroll-down-up",
      "mkdf-dropdown-animate-height",
      "mkdf-header-standard",
      "mkdf-menu-area-shadow-disable",
      "mkdf-menu-area-in-grid-shadow-disable",
      "mkdf-menu-area-border-disable",
      "mkdf-menu-area-in-grid-border-disable",
      "mkdf-logo-area-border-disable",
      "mkdf-woocommerce-page",
      "mkdf-woo-single-page",
      "mkdf-woocommerce-columns-4",
      "mkdf-woo-medium-space",
      "mkdf-woo-pl-info-below-image",
      "mkdf-woo-single-thumb-on-left-side",
      "mkdf-woo-single-has-pretty-photo",
      "mkdf-default-mobile-header",
      "mkdf-sticky-up-mobile-header",
      "mkdf-slide-from-header-bottom",
      "wpb-js-composer",
      "js-comp-ver-6.6.0",
      "vc_responsive"
    );
    document.body.setAttribute("data-new-gr-c-s-check-loaded", "14.1047.0");
    document.body.setAttribute("data-gr-ext-installed", "");
    window.scrollTo(0, 0);
  }, []);

  const [quantity, setQuantity] = useState(1);

  return (
    <div className="mkdf-content">
      <div className="mkdf-content-inner">
        <div className="mkdf-wrapper">
          <div className="mkdf-wrapper-inner">
            <a id="mkdf-back-to-top" href="#">
              <span className="mkdf-icon-stack">
                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>{" "}
                <i className="mkdf-icon-font-awesome fa fa-angle-up "></i>
              </span>
            </a>
            <div className="mkdf-content">
              <div className="mkdf-content-inner">
                <div className="mkdf-container">
                  <div className="mkdf-container-inner clearfix">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div
                      id="product-365"
                      className="product type-product post-365 status-publish first instock product_cat-popular product_cat-winter product_tag-llilies product_tag-roses has-post-thumbnail shipping-taxable purchasable product-type-simple"
                    >
                      <div className="mkdf-single-product-content">
                        <div
                          className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images"
                          data-columns="4"
                        >
                          <div className="woocommerce-product-gallery__wrapper">
                            <div
                              data-thumb={item.image}
                              data-thumb-alt="l"
                              className="woocommerce-product-gallery__image"
                            >
                              <a href={item.image}>
                                <img
                                  width="620"
                                  height="800"
                                  src={item.image}
                                  className="wp-post-image"
                                  alt="l"
                                  title="shop-14-img"
                                  data-caption=""
                                  data-src={item.image}
                                  data-large_image={item.image}
                                  data-large_image_width="620"
                                  data-large_image_height="800"
                                />
                              </a>
                            </div>

                            {item.image1 && (
                              <div
                                data-thumb={item.image1}
                                className="woocommerce-product-gallery__image"
                              >
                                <a href={item.image}>
                                  <img
                                    width="300"
                                    height="400"
                                    src={item.image1}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt="k"
                                    title="shop-14-gallery-4"
                                    data-caption=""
                                    data-src={item.image}
                                    data-large_image={item.image}
                                    data-large_image_width="465"
                                    data-large_image_height="600"
                                  />
                                </a>
                              </div>
                            )}
                            {item.image2 && (
                              <div
                                data-thumb={item.image2}
                                className="woocommerce-product-gallery__image"
                              >
                                <a href={item.image2}>
                                  <img
                                    width="300"
                                    height="400"
                                    src={item.image}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt="k"
                                    title="shop-14-gallery-4"
                                    data-caption=""
                                    data-src={item.image2}
                                    data-large_image={item.image}
                                    data-large_image_width="465"
                                    data-large_image_height="600"
                                  />
                                </a>
                              </div>
                            )}
                            {item.image3 && (
                              <div
                                data-thumb={item.image3}
                                className="woocommerce-product-gallery__image"
                              >
                                <a href={item.image3}>
                                  <img
                                    width="300"
                                    height="400"
                                    src={item.image3}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt="k"
                                    title="shop-14-gallery-4"
                                    data-caption=""
                                    data-src={item.image}
                                    data-large_image={item.image}
                                    data-large_image_width="465"
                                    data-large_image_height="600"
                                  />
                                </a>
                              </div>
                            )}
                            {item.image4 && (
                              <div
                                data-thumb={item.image4}
                                className="woocommerce-product-gallery__image"
                              >
                                <a href={item.image}>
                                  <img
                                    width="300"
                                    height="400"
                                    src={item.image4}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                    alt="k"
                                    title="shop-14-gallery-4"
                                    data-caption=""
                                    data-src={item.image}
                                    data-large_image={item.image}
                                    data-large_image_width="465"
                                    data-large_image_height="600"
                                  />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mkdf-single-product-summary">
                          <div className="summary entry-summary">
                            <h2
                              itemprop="name"
                              className="mkdf-single-product-title"
                            >
                              {item.name}
                            </h2>
                            <p className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₺
                                  </span>
                                  {item.price}
                                </bdi>
                              </span>
                            </p>
                            <div className="woocommerce-product-details__short-description">
                              <p>{item.shortDescription}</p>
                            </div>
                            <form
                              className="cart"
                              action="https://fiorello.qodeinteractive.com/product/majesty-palm/"
                              method="post"
                              enctype="multipart/form-data"
                            >
                              <div className="mkdf-quantity-buttons quantity">
                                <label
                                  className="screen-reader-text"
                                  for="quantity_61d9c471c9232"
                                >
                                  Miktar
                                </label>
                                <span
                                  className="mkdf-quantity-label"
                                  for="quantity_61d9c471c9232"
                                >
                                  Miktar
                                </span>
                                <span
                                  className="mkdf-quantity-minus arrow_triangle-left"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setQuantity(quantity - 1);
                                  }}
                                ></span>
                                <input
                                  type="text"
                                  id="quantity_61d9c471c9232"
                                  className="input-text qty text mkdf-quantity-input"
                                  data-min={1}
                                  data-step={1}
                                  data-max=""
                                  name="quantity"
                                  value={quantity}
                                  title="Qty"
                                  size="4"
                                  placeholder=""
                                  inputmode="numeric"
                                />
                                <span
                                  className="mkdf-quantity-plus arrow_triangle-right"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setQuantity(quantity + 1);
                                  }}
                                ></span>
                              </div>
                              <button
                                type="submit"
                                name="add-to-cart"
                                value="365"
                                className="single_add_to_cart_button button alt"
                                onClick={(e) => {
                                  e.preventDefault();
                                  AddToCart(item, quantity);
                                }}
                              >
                                Sepete Ekle
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="woocommerce-tabs wc-tabs-wrapper">
                        <ul className="tabs wc-tabs" role="tablist">
                          <li
                            className="description_tab active"
                            id="tab-title-description"
                            role="tab"
                            aria-controls="tab-description"
                          >
                            <a href="#tab-description">Detay </a>
                          </li>

                          {/*<li
                        className="reviews_tab"
                        id="tab-title-reviews"
                        role="tab"
                        aria-controls="tab-reviews"
                      >
                        <a href="#tab-reviews">Değerlendirme (2) </a>
                      </li>*/}
                        </ul>
                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                          id="tab-description"
                          role="tabpanel"
                          aria-labelledby="tab-title-description"
                        >
                          <h2>Detay </h2>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="mkdf-section-title-holder   mkdf-st-subtitle-pos-under">
                                    <div className="mkdf-st-inner">
                                      <p className="mkdf-st-text">
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  <div
                      className="woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab"
                      id="tab-reviews"
                      role="tabpanel"
                      aria-labelledby="tab-title-reviews"
                    >
                      <div id="reviews" className="woocommerce-Reviews">
                        <div id="comments">
                          <h2 className="woocommerce-Reviews-title">
                            Değerlendirmeler
                          </h2>
                          <ol className="commentlist">
                            <li
                              className="review even thread-even depth-1"
                              id="li-comment-22"
                            >
                              <div
                                id="comment-22"
                                className="comment_container"
                              >
                                <img
                                  data-del="avatar"
                                  src="wp-content/uploads/2018/04/user-img-2-100x100.png"
                                  class="avatar pp-user-avatar avatar-60 photo "
                                  height="60"
                                  width="60"
                                />
                                <div className="comment-text">
                                  <p className="meta">
                                    <strong className="woocommerce-review__author">
                                      Joan Stanley{" "}
                                    </strong>
                                    <span className="woocommerce-review__dash">
                                      &ndash;
                                    </span>{" "}
                                    <time
                                      className="woocommerce-review__published-date"
                                      datetime="2018-04-30T07:17:07+00:00"
                                    >
                                      30.04.2018
                                    </time>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetuer
                                      adipiscing elit. Aenean commodo ligula
                                      eget dolor. Aenean massa. Cum sociis Theme
                                      natoque penatibus et magnis dis parturient
                                      montes, nascetur ridiculus mus.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className="review odd alt thread-odd thread-alt depth-1"
                              id="li-comment-23"
                            >
                              <div
                                id="comment-23"
                                className="comment_container"
                              >
                                <img
                                  data-del="avatar"
                                  src="wp-content/uploads/2018/04/user-img-1-100x100.png"
                                  class="avatar pp-user-avatar avatar-60 photo "
                                  height="60"
                                  width="60"
                                />
                                <div className="comment-text">
                                  <p className="meta">
                                    <strong className="woocommerce-review__author">
                                      Joshua Garcia{" "}
                                    </strong>
                                    <span className="woocommerce-review__dash">
                                      &ndash;
                                    </span>{" "}
                                    <time
                                      className="woocommerce-review__published-date"
                                      datetime="2018-04-30T07:17:27+00:00"
                                    >
                                      30.04.2018
                                    </time>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Etiam rhoncus. Maecenas tempus, tellus
                                      eget condimentum rhoncus, sem quam semper
                                      libero, sit amet adipiscing sem neque sed
                                      ipsum. Nam quam nunc, blandit vel, luctus
                                      pulvinar, hendrerit
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                        <div id="review_form_wrapper">
                          <div id="review_form">
                            <div id="respond" className="comment-respond">
                              <span
                                id="reply-title"
                                className="comment-reply-title"
                              >
                                Add a review{" "}
                                <small>
                                  <a
                                    rel="nofollow"
                                    id="cancel-comment-reply-link"
                                    href="index.html#respond"
                                    style={{ display: "none" }}
                                  >
                                    Cancel reply
                                  </a>
                                </small>
                              </span>
                              <form
                                action="https://fiorello.qodeinteractive.com/wp-comments-post.php"
                                method="post"
                                id="commentform"
                                className="comment-form"
                              >
                                <p className="comment-notes">
                                  <span id="email-notes">
                                    Your email address will not be published.
                                  </span>{" "}
                                  Required fields are marked{" "}
                                  <span className="required">*</span>
                                </p>
                                <p className="comment-form-comment">
                                  <label for="comment">
                                    Your review&nbsp;
                                    <span className="required">*</span>
                                  </label>
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    cols="45"
                                    rows="8"
                                    required
                                  ></textarea>
                                </p>
                                <p className="comment-form-author">
                                  <label for="author">
                                    Name&nbsp;
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    id="author"
                                    name="author"
                                    type="text"
                                    value=""
                                    size="30"
                                    required
                                  />
                                </p>
                                <p className="comment-form-email">
                                  <label for="email">
                                    Email&nbsp;
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value=""
                                    size="30"
                                    required
                                  />
                                </p>
                                <p className="comment-form-cookies-consent">
                                  <input
                                    id="wp-comment-cookies-consent"
                                    name="wp-comment-cookies-consent"
                                    type="checkbox"
                                    value="yes"
                                  />{" "}
                                  <label for="wp-comment-cookies-consent">
                                    Save my name, email, and website in this
                                    browser for the next time I comment.
                                  </label>
                                </p>
                                <p className="form-submit">
                                  <input
                                    name="submit"
                                    type="submit"
                                    id="submit"
                                    className="submit"
                                    value="Submit"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="comment_post_ID"
                                    value="365"
                                    id="comment_post_ID"
                                  />
                                  <input
                                    type="hidden"
                                    name="comment_parent"
                                    id="comment_parent"
                                    value="0"
                                  />
                                </p>
                                <div className="wantispam-required-fields">
                                  <input
                                    type="hidden"
                                    name="wantispam_t"
                                    className="wantispam-control wantispam-control-t"
                                    value="1641661553"
                                  />
                                  <div
                                    className="wantispam-group wantispam-group-q"
                                    style={{ clear: "both" }}
                                  >
                                    <label>
                                      Current{" "}
                                      <a
                                        href="../../cdn-cgi/l/email-protection.html"
                                        className="__cf_email__"
                                        data-cfemail="dca5b99cae"
                                      >
                                        [email&#160;protected]
                                      </a>{" "}
                                      <span className="required">*</span>
                                    </label>
                                    <input
                                      type="hidden"
                                      name="wantispam_a"
                                      className="wantispam-control wantispam-control-a"
                                      value="2022"
                                    />
                                    <input
                                      type="text"
                                      name="wantispam_q"
                                      className="wantispam-control wantispam-control-q"
                                      value="7.2.9"
                                      autocomplete="off"
                                    />
                                  </div>
                                  <div
                                    className="wantispam-group wantispam-group-e"
                                    style={{ display: "none" }}
                                  >
                                    <label>Leave this field empty</label>
                                    <input
                                      type="text"
                                      name="wantispam_e_email_url_website"
                                      className="wantispam-control wantispam-control-e"
                                      value=""
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                              </form>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="clear"></div>
                      </div>
                    </div>*/}
                      </div>
                      {/*
                   <section className="related products">
                    <h2>Related products</h2>
                    <ul className="products columns-4">
                      <li className="product type-product post-246 status-publish first instock product_cat-various product_cat-greens product_tag-roses product_tag-tulips has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="mkdf-pl-inner">
                          <div className="mkdf-pl-image">
                            <img
                              width="300"
                              height="400"
                              src="wp-content/uploads/2018/05/shop-1-img-300x400.jpg"
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                              alt="h"
                            />
                            <span className="mkdf-new-product">New</span>
                            <div className="mkdf-pl-text mkdf-pl-hover-overlay">
                              <div className="mkdf-pl-text-outer">
                                <div className="mkdf-pl-text-inner"></div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="../schefflera/index.html"
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                          ></a>
                        </div>
                        <div className="mkdf-pl-text-wrapper">
                          <h6 className="mkdf-product-list-title">
                            <a href="../schefflera/index.html">Schefflera</a>
                          </h6>
                          <div className="mkdf-price-holder">
                            <span className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    &#36;
                                  </span>
                                  259
                                </bdi>
                              </span>
                            </span>
                            <div className="mkdf-pl-add-to-cart mkdf-default-skin">
                              <a
                                rel="nofollow"
                                href="indexcb0b.html?add-to-cart=246"
                                data-quantity="1"
                                data-product_id="246"
                                data-product_sku="563654"
                                className="button product_type_simple add_to_cart_button ajax_add_to_cart mkdf-pl-btn"
                              >
                                Add to cart
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="product type-product post-363 status-publish outofstock product_cat-popular product_cat-winter product_tag-llilies product_tag-orchids has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="mkdf-pl-inner">
                          <div className="mkdf-pl-image">
                            <img
                              width="300"
                              height="400"
                              src="wp-content/uploads/2018/05/shop-10-img-300x400.jpg"
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                              alt="d"
                            />
                            <span className="mkdf-out-of-stock">Sold</span>
                            <div className="mkdf-pl-text mkdf-pl-hover-overlay">
                              <div className="mkdf-pl-text-outer">
                                <div className="mkdf-pl-text-inner"></div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="../sweet-alyssum/index.html"
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                          ></a>
                        </div>
                        <div className="mkdf-pl-text-wrapper">
                          <h6 className="mkdf-product-list-title">
                            <a href="../sweet-alyssum/index.html">
                              Sweet Alyssum
                            </a>
                          </h6>
                          <div className="mkdf-price-holder">
                            <span className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    &#36;
                                  </span>
                                  259
                                </bdi>
                              </span>
                            </span>
                            <div className="mkdf-pl-add-to-cart mkdf-default-skin">
                              <a
                                rel="nofollow"
                                href="../sweet-alyssum/index.html"
                                data-quantity="1"
                                data-product_id="363"
                                data-product_sku="5555"
                                className="button product_type_simple ajax_add_to_cart mkdf-pl-btn"
                              >
                                Read more
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="product type-product post-251 status-publish instock product_cat-various product_cat-exotic product_tag-llilies product_tag-orchids has-post-thumbnail shipping-taxable purchasable
 product-type-simple"
                      >
                        <div className="mkdf-pl-inner">
                          <div className="mkdf-pl-image">
                            <img
                              width="300"
                              height="400"
                              src="wp-content/uploads/2018/05/shop-2-img-300x400.jpg"
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                              alt="c"
                            />
                            <div className="mkdf-pl-text mkdf-pl-hover-overlay">
                              <div className="mkdf-pl-text-outer">
                                <div className="mkdf-pl-text-inner"></div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="../orange-amaryllis/index.html"
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                          ></a>
                        </div>
                        <div className="mkdf-pl-text-wrapper">
                          <h6 className="mkdf-product-list-title">
                            <a href="../orange-amaryllis/index.html">
                              Orange Amaryllis
                            </a>
                          </h6>
                          <div className="mkdf-price-holder">
                            <span className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    &#36;
                                  </span>
                                  259
                                </bdi>
                              </span>
                            </span>
                            <div className="mkdf-pl-add-to-cart mkdf-default-skin">
                              <a
                                rel="nofollow"
                                href="indexa528.html?add-to-cart=251"
                                data-quantity="1"
                                data-product_id="251"
                                data-product_sku="989873"
                                className="button product_type_simple add_to_cart_button ajax_add_to_cart mkdf-pl-btn"
                              >
                                Add to cart
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        className="product type-product post-268 status-publish last instock product_cat-various product_cat-cactuses product_tag-orchids product_tag-roses has-post-thumbnail sale shipping-taxable
 purchasable product-type-simple"
                      >
                        <div className="mkdf-pl-inner">
                          <div className="mkdf-pl-image">
                            <span className="mkdf-onsale">Sale</span>
                            <img
                              width="300"
                              height="400"
                              src="wp-content/uploads/2018/05/shop-5-img-300x400.jpg"
                              className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                              alt="h"
                            />
                            <div
                              div
                              className="mkdf-pl-text mkdf-pl-hover-overlay"
                            >
                              <div className="mkdf-pl-text-outer">
                                <div className="mkdf-pl-text-inner"></div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="../wild-cactus/index.html"
                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                          ></a>
                        </div>
                        <div className="mkdf-pl-text-wrapper">
                          <h6 className="mkdf-product-list-title">
                            <a href="../wild-cactus/index.html">Wild Cactus</a>
                          </h6>
                          <div className="mkdf-price-holder">
                            <span className="price">
                              <del aria-hidden="true">
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      &#36;
                                    </span>
                                    259
                                  </bdi>
                                </span>
                              </del>{" "}
                              <ins>
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      &#36;
                                    </span>
                                    159
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                            <div className="mkdf-pl-add-to-cart mkdf-default-skin">
                              <a
                                rel="nofollow"
                                href="indexee06.html?add-to-cart=268"
                                data-quantity="1"
                                data-product_id="268"
                                data-product_sku="68547"
                                className="button product_type_simple add_to_cart_button ajax_add_to_cart mkdf-pl-btn"
                              >
                                Add to cart
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </section>
                   */}
                    </div>
                  </div>
                </div>
                <div className="mkdf-content-bottom">
                  <div className="mkdf-content-bottom-inner mkdf-full-width">
                    <div className="widget widget_mkdf_instagram_widget"></div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;

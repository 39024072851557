import React, { useEffect, useState } from "react";
import "./css/style.css";

import { useNavigate, Link } from "react-router-dom";
import { auth, firestore } from "../../utils/firebase";
import logo from "../../assets/images/logo.jpg";

const Sidebar = ({ active, handleCheckbox }) => {
  const navigate = useNavigate();
  const signOut = () => {
    auth.signOut().then(() => {
      navigate("/admin/giris");
    });
  };
  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"
    >
      <header className="topbar">
        <nav className="navbar top-navbar ">
          <div className="navbar-header" data-logobg="skin6">
            <a className="navbar-brand ml-4" href="#">
              <b className="logo-icon">
                <h3>Atakum Çiçekçilik</h3>
              </b>
            </a>
          </div>
        </nav>
      </header>

      <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin"
                  aria-expanded="false"
                >
                  <i className="far fa-clock" aria-hidden="true"></i>
                  <span className="hide-menu">Ana Sayfa</span>
                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <a
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  href="/admin/siparisler"
                  aria-expanded="false"
                >
                  <i className=" fab fa-microsoft" aria-hidden="true"></i>
                  <span className="hide-menu">Gelen Siparişler</span>
                </a>
              </li>
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin/menu"
                  aria-expanded="false"
                >
                  <i className="fab fa-leanpub" aria-hidden="true"></i>
                  <span className="hide-menu">Menü</span>
                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <a
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  onClick={handleCheckbox}
                  aria-expanded="false"
                >
                  {active ? (
                    <>
                      <i
                        style={{ color: "#5CFF5C" }}
                        className="fas fa-bell"
                        aria-hidden="true"
                      ></i>
                      <span className="hide-menu">Yoğunluğa Al</span>
                    </>
                  ) : (
                    <>
                      <i
                        style={{ color: "#FF5C5C" }}
                        className="fas fa-bell-slash"
                        aria-hidden="true"
                      ></i>
                      <span className="hide-menu">Aktif Et</span>
                    </>
                  )}
                </a>
              </li>
              <li className="sidebar-item pt-2">
                <a
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  onClick={signOut}
                  aria-expanded="false"
                >
                  <i className="fa fa-sign-out-alt" aria-hidden="true"></i>
                  <span className="hide-menu">Çıkış Yap</span>
                </a>
              </li>{" "}
              {/* <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin/gecmis"
                  aria-expanded="false"
                >
                  <i className=" far fa-chart-bar" aria-hidden="true"></i>
                  <span className="hide-menu">Geçmiş Siparişler</span>
                </Link>
  </li>*/}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;

import React from "react";
const Contact = () => {
  return (
    <>
      <div className="mkdf-content">
        <div className="mkdf-content-inner">
          <div className="mkdf-full-width">
            <div className="mkdf-full-width-inner">
              <div className="mkdf-grid-row">
                <div className="mkdf-page-content-holder mkdf-grid-col-12">
                  <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="mkdf-google-map-holder">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5991.392359948809!2d36.247123703025736!3d41.33721951059901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4088791cf0f54531%3A0x99279fb3129d8a9!2sAtakum%20%C3%87i%C3%A7ek%C3%A7ilik-%20Royal%20Flowers!5e0!3m2!1str!2str!4v1643461294529!5m2!1str!2str"
                              width="100%"
                              height="450"
                              style={{ border: 0, marginTop: -50 }}
                              allowfullscreen=""
                              loading="lazy"
                            ></iframe>
                            <div className="mkdf-google-map-overlay"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mkdf-row-grid-section-wrapper ">
                    <div className="mkdf-row-grid-section">
                      <div className="vc_row wpb_row vc_row-fluid vc_custom_1526293057880">
                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0 vc_col-xs-12 vc_col-has-fill">
                          <div className="vc_column-inner vc_custom_1525356489904">
                            <div className="wpb_wrapper">
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      width="154"
                                      height="154"
                                      src="../wp-content/uploads/2018/05/contact-img-1.png"
                                      className="vc_single_image-img attachment-full"
                                      alt="d"
                                      srcset="https://fiorello.qodeinteractive.com/wp-content/uploads/2018/05/contact-img-1.png 154w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/05/contact-img-1-100x100.png 100w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/05/contact-img-1-50x50.png 50w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/05/contact-img-1-150x150.png 150w"
                                      sizes="(max-width: 154px) 100vw, 154px"
                                    />
                                  </div>
                                </figure>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 17 }}
                              >
                                <span className="vc_empty_space_inner"></span>
                              </div>
                              <div
                                className="mkdf-section-title-holder   mkdf-st-subtitle-pos-under"
                                style={{ textAlign: "center" }}
                              >
                                <div className="mkdf-st-inner">
                                  <h2 className="mkdf-st-title">
                                    Royal Flowers Atakum Çiçekçilik
                                  </h2>
                                  <p
                                    className="mkdf-st-text"
                                    style={{ fontSize: 17, marginTop: 29 }}
                                  >
                                    0532 404 64 41
                                    <br />
                                    Yenimahalle, İsmet İnönü Cd. No:43, 55270
                                    <br />
                                    Atakum/Samsun
                                  </p>
                                </div>
                              </div>
                              <div
                                className="vc_empty_space"
                                style={{ height: 42 }}
                              >
                                <span className="vc_empty_space_inner"></span>
                              </div>
                              <div className="wpb_single_image wpb_content_element vc_align_center">
                                <figure className="wpb_wrapper vc_figure">
                                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                                    <img
                                      width="197"
                                      height="56"
                                      src="../wp-content/uploads/2018/04/h2-sign-img.png"
                                      className="vc_single_image-img attachment-full"
                                      alt="s"
                                      srcset="https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h2-sign-img.png 197w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/h2-sign-img-75x21.png 75w"
                                      sizes="(max-width: 197px) 100vw, 197px"
                                    />
                                  </div>
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-0 vc_col-lg-6 vc_col-md-offset-1 vc_col-md-10 vc_col-sm-offset-0 vc_col-xs-12">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className="mkdf-elements-holder   mkdf-one-column  mkdf-responsive-mode-768 ">
                                <div
                                  className="mkdf-eh-item      "
                                  data-item-
                                  className="mkdf-eh-custom-4696"
                                  data-1366-1600="2px 0 0 35px"
                                  data-1024-1366="30px 0 0 35px"
                                  data-768-1024="57px 0 0 0"
                                  data-680-768="57px 0 0 0"
                                  data-680="62px 0 0 0"
                                >
                                  <div className="mkdf-eh-item-background-image"></div>
                                  <div className="mkdf-eh-item-inner">
                                    <div
                                      className="mkdf-eh-item-content mkdf-eh-custom-4696"
                                      style={{ padding: "2px 0 0 35px" }}
                                    >
                                      <div className="mkdf-section-title-holder   mkdf-st-subtitle-pos-under">
                                        <div className="mkdf-st-inner">
                                          <h2 className="mkdf-st-title">
                                            İletişime Geçin!
                                          </h2>
                                          <p
                                            className="mkdf-st-text"
                                            style={{ marginTop: 0 }}
                                          >
                                            Class aptent taciti sociosqu ad
                                            litora torquent per conubia nostr.
                                            Mauris in erat justullam ac urna eu
                                            felis dapib{" "}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="vc_empty_space"
                                        style={{ height: 32 }}
                                      >
                                        <span className="vc_empty_space_inner"></span>
                                      </div>
                                      <div
                                        role="form"
                                        className="wpcf7"
                                        id="wpcf7-f707-p693-o1"
                                        lang="en-US"
                                        dir="ltr"
                                      >
                                        <div className="screen-reader-response">
                                          <p
                                            role="status"
                                            aria-live="polite"
                                            aria-atomic="true"
                                          ></p>{" "}
                                          <ul></ul>
                                        </div>
                                        <form
                                          action="https://fiorello.qodeinteractive.com/contact-us/#wpcf7-f707-p693-o1"
                                          method="post"
                                          className="wpcf7-form init cf7_custom_style_2"
                                          novalidate="novalidate"
                                          data-status="init"
                                        >
                                          <div style={{ display: "none" }}>
                                            <input
                                              type="hidden"
                                              name="_wpcf7"
                                              value="707"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_version"
                                              value="5.5.3"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_locale"
                                              value="en_US"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_unit_tag"
                                              value="wpcf7-f707-p693-o1"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_container_post"
                                              value="693"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_posted_data_hash"
                                              value=""
                                            />
                                          </div>
                                          <p>
                                            <span className="wpcf7-form-control-wrap your-name">
                                              <input
                                                type="text"
                                                name="your-name"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true"
                                                aria-invalid="false"
                                                placeholder="İsminiz"
                                              />
                                            </span>
                                            <br />
                                            <span className="wpcf7-form-control-wrap your-email">
                                              <input
                                                type="email"
                                                name="your-email"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                aria-required="true"
                                                aria-invalid="false"
                                                placeholder="Email"
                                              />
                                            </span>
                                            <br />
                                            <span className="wpcf7-form-control-wrap Phone">
                                              <input
                                                type="tel"
                                                name="Phone"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                                aria-invalid="false"
                                                placeholder="Telefon"
                                              />
                                            </span>
                                            <br />
                                            <span className="wpcf7-form-control-wrap your-message">
                                              <textarea
                                                name="your-message"
                                                cols="40"
                                                rows="10"
                                                className="wpcf7-form-control wpcf7-textarea"
                                                aria-invalid="false"
                                                placeholder="İsteğiniz veya Şikayetleriniz..."
                                              ></textarea>
                                            </span>
                                            <div style={{ marginTop: 20 }}>
                                              <input
                                                type="submit"
                                                value="Gönder"
                                                className="wpcf7-form-control has-spinner wpcf7-submit"
                                              />
                                            </div>
                                            <div
                                              className="wpcf7-response-output"
                                              aria-hidden="true"
                                            ></div>
                                          </p>
                                        </form>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mkdf-content-bottom">
          <div className="mkdf-content-bottom-inner mkdf-full-width">
            <div className="widget widget_mkdf_instagram_widget"></div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;

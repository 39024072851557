import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDclXwNtdt0PEoIxi7GoiFuf1ACGKmABts",
  authDomain: "alka-8f0e3.firebaseapp.com",
  projectId: "alka-8f0e3",
  storageBucket: "alka-8f0e3.appspot.com",
  messagingSenderId: "789571004748",
  appId: "1:789571004748:web:36e1c539545bd4615207e9",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export default firebase;

import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { firestore } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";
import { createToast } from "../../utils/Utils";

const Checkout = ({ cart, RemoveCart }) => {
  const [form, setForm] = useState({
    name: "",
    address: "",
    phone: "",
    note: "",
  });

  const navigate = useNavigate();

  const giveOrder = () => {
    if (cart.length < 1) {
      alert("Sepette Ürün Yok!");
      return;
    }
    let check = false;
    console.log(form);
    if (
      form.address?.trim() == "" ||
      form.name?.trim() == "" ||
      form.phone?.trim() == ""
    ) {
      check = true;
    }
    if (check) {
      alert("Eksik Giriş!");
      return;
    }
    let id = uuid();
    firestore
      .collection("atakum-cicekci")
      .doc("orders")
      .set(
        {
          [id]: {
            id,
            ...form,
            cart,
            status: 0,
          },
        },
        { merge: true }
      )
      .then(() => {
        RemoveCart();
        createToast("Siparişiniz İletildi!", 0);
        navigate("/");
      });
  };

  return (
    <div className="mkdf-content">
      <div className="mkdf-content-inner">
        <div
          className="mkdf-title-holder mkdf-standard-type mkdf-title-va-header-bottom mkdf-title-content-va-middle"
          style={{ height: 225, backgroundColor: "#fafaf92" }}
          data-height="225"
        >
          <div className="mkdf-title-wrapper" style={{ height: 225 }}>
            <div className="mkdf-title-inner">
              <div className="mkdf-grid">
                <h1 className="mkdf-page-title entry-title">Sipariş Ver</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mkdf-container mkdf-default-page-template">
          <div className="mkdf-container-inner clearfix">
            <div className="mkdf-grid-row">
              <div className="mkdf-page-content-holder mkdf-grid-col-12">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper"></div>
                  </div>
                </div>
                <div className="woocommerce">
                  <div className="woocommerce-notices-wrapper"></div>

                  <div className="woocommerce-notices-wrapper"></div>

                  <div className="col2-set" id="customer_details">
                    <div className="col-1">
                      <div className="woocommerce-billing-fields">
                        <h3>Sipariş Bilgileri</h3>

                        <div className="woocommerce-billing-fields__field-wrapper">
                          <p
                            className="form-row form-row-first validate-required"
                            id="billing_first_name_field"
                            data-priority="10"
                          >
                            <label for="billing_first_name" className="">
                              İsim&nbsp;
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text "
                                placeholder="İsim"
                                value={form.name}
                                style={{ width: "40vw" }}
                                onChange={(e) =>
                                  setForm({ ...form, name: e.target.value })
                                }
                                autocomplete="given-name"
                              />
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="woocommerce-billing-fields">
                        <div className="woocommerce-billing-fields__field-wrapper">
                          <p
                            className="form-row form-row-first validate-required"
                            id="billing_first_name_field"
                            data-priority="10"
                          >
                            <label for="billing_first_name" className="">
                              Adres&nbsp;
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text "
                                placeholder="Adres"
                                value={form.address}
                                style={{ width: "40vw" }}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    address: e.target.value,
                                  })
                                }
                                autocomplete="given-name"
                              />
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="woocommerce-billing-fields">
                        <div className="woocommerce-billing-fields__field-wrapper">
                          <p
                            className="form-row form-row-first validate-required"
                            id="billing_first_name_field"
                            data-priority="10"
                          >
                            <label for="billing_first_name" className="">
                              Telefon&nbsp;
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text"
                                placeholder="Telefon Numarası"
                                value={form.phone}
                                style={{ width: "40vw" }}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    phone: e.target.value,
                                  })
                                }
                                autocomplete="given-name"
                              />
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="woocommerce-billing-fields">
                        <div className="woocommerce-billing-fields__field-wrapper">
                          <p
                            className="form-row form-row-first validate-required"
                            data-priority="10"
                          >
                            <label for="billing_first_name" className="">
                              Sipariş Notu&nbsp;
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <span className="woocommerce-input-wrapper">
                              <input
                                type="text"
                                className="input-text "
                                placeholder="Notunuz..."
                                value={form.note}
                                style={{ width: "40vw", height: "20vh" }}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    note: e.target.value,
                                  })
                                }
                                autocomplete="given-name"
                              />
                            </span>
                          </p>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          value="Sipariş Ver"
                          className="button alt"
                          onClick={giveOrder}
                        >
                          Gönder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./pages/Client/Main";
import Login from "./pages/Panel/Login";
import Panel from "./pages/Panel/Panel";

const App = () => {
  return (
    <Routes>
      <Route path="/*" element={<Main />} />
      <Route path="/admin/giris" element={<Login />} />
      <Route path="/admin/*" element={<Panel />} />
    </Routes>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "../../components/Client/Footer";
import Header from "../../components/Client/Header";
import Cart from "./Cart";
import Contact from "./Contact";
import Index from "./Index";
import { firestore } from "../../utils/firebase";
import { createToast } from "../../utils/Utils";
import Product from "./Product";
import "./style.css";
import Checkout from "./Checkout";
import Category from "./Category";

const Main = () => {
  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    firestore
      .collection("atakum-cicekci")
      .doc("menu")
      .get()
      .then((doc) => {
        let menu = doc.data().Menu.filter((m) => m.active == 1);
        setMenu(menu);

        setCategories(
          menu.map((cat) => ({
            id: cat.id,
            name: cat.name,
            sortOrder: cat.sortOrder,
            image: cat.image,
            items: cat.Meals
          }))
        );
      });
  }, []);

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const AddToCart = (item, quantity = 1) => {
    let cartCopy = [...cart];
    let { id } = item;

    let existingItem = cartCopy.find((cartItem) => cartItem.id == id);

    if (existingItem) {
      existingItem.quantity += quantity; //update item
    } else {
      cartCopy.push({ ...item, quantity });
    }

    setCart(cartCopy);
    createToast(item.name + " Sepete Eklendi", 0);
  };
  const RemoveFromCart = (obj) => {
    let new_cart = cart;
    cart.forEach((item, i, new_cart) => {
      if (item.id == obj.id) {
        new_cart[i].quantity -= 1;
      }
    });
    setCart(new_cart.filter((f) => f.quantity != 0));
    createToast(obj.name + " Sepetten çıkarıldı", 0);
  };
  const DeleteFromCart = (item) => {
    setCart(cart.filter((f) => f.id != item.id));
    createToast(item.name + " Sepetten Çıkarıldı", 0);
  };
  const RemoveCart = () => {
    setCart([]);
  };
  return (
    <>
      <Header cart={cart} RemoveFromCart={RemoveFromCart} />

      <Routes>
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/urun" element={<Product AddToCart={AddToCart} />} />
        <Route path="/kategori" element={<Category AddToCart={AddToCart} />} />
        <Route
          path="/odeme"
          element={<Checkout cart={cart} RemoveCart={RemoveCart} />}
        />
        <Route
          path="/sepet"
          element={
            <Cart
              cart={cart}
              AddToCart={AddToCart}
              RemoveFromCart={RemoveFromCart}
              DeleteFromCart={DeleteFromCart}
            />
          }
        />
        <Route
          exact
          path="/"
          element={
            <Index
              menu={menu}
              categories={categories}
              cart={cart}
              AddToCart={AddToCart}
              RemoveFromCart={RemoveFromCart}
              DeleteFromCart={DeleteFromCart}
            />
          }
        />
      </Routes>
      <Footer />
      <div
        className="rbt-toolbar"
        data-theme="Fiorello"
        data-featured=""
        data-button-position="69%"
        data-button-horizontal="right"
        data-button-alt="no"
      ></div>
    </>
  );
};

export default Main;

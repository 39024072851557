import React from "react";

export const Footer = () => {
  return (
    <footer className="mkdf-page-footer ">
      <div className="mkdf-footer-top-holder">
        <div className="mkdf-footer-top-inner mkdf-grid">
          <div className="mkdf-grid-row mkdf-footer-top-alignment-left">
            <div className="mkdf-column-content mkdf-grid-col-3">
              <div
                id="nav_menu-4"
                className="widget mkdf-footer-column-1 widget_nav_menu"
              ></div>{" "}
            </div>
            <div className="mkdf-column-content mkdf-grid-col-3"></div>

            <div className="mkdf-column-content mkdf-grid-col-3"></div>
            <div className="mkdf-column-content mkdf-grid-col-3">
              <div
                id="nav_menu-3"
                className="widget mkdf-footer-column-3 widget_nav_menu"
              >
                <div className="mkdf-widget-title-holder">
                  <h6 className="mkdf-widget-title">Sosyal Medya</h6>
                </div>
                <div className="menu-social-media-container">
                  <ul id="menu-social-media" className="menu">
                    <li
                      id="menu-item-110"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-110"
                    >
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.instagram.com/cicekcilik_atakum/"
                      >
                        Instagram
                      </a>
                    </li>

                    <li
                      id="menu-item-112"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-112"
                    >
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.facebook.com/ROYAL-Flowers-%C3%87i%C3%A7ekcilikatakum%C3%A7obanli-102479944903668/"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-footer-bottom-holder">
        <div className="mkdf-footer-bottom-inner mkdf-grid">
          <div className="mkdf-grid-row ">
            <div className="mkdf-grid-col-4">
              <div
                id="text-2"
                className="widget mkdf-footer-bottom-column-1 widget_text"
              >
                {" "}
                <div className="textwidget">
                  <div>
                    © {new Date().getFullYear()}{" "}
                    <a
                      href="https://dbateknoloji.com/"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      DBA Teknoloji Yazılım
                    </a>
                    , Bütün Hakları Saklıdır
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="mkdf-grid-col-4">
              <div
                id="media_image-2"
                className="widget mkdf-footer-bottom-column-2 widget_media_image"
              >
                <a href="#">
                  <img
                    width="179"
                    height="21"
                    src="wp-content/uploads/2018/04/footer-bottom-1.png"
                    className="image wp-image-101  attachment-full size-full"
                    alt="d"
                    style={{ maxWwidth: "100%", height: "auto" }}
                    srcset="https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/footer-bottom-1.png 179w, https://fiorello.qodeinteractive.com/wp-content/uploads/2018/04/footer-bottom-1-75x9.png 75w"
                    sizes="(max-width: 179px) 100vw, 179px"
                  />
                </a>
              </div>{" "}
            </div>
            <div className="mkdf-grid-col-4">
              <a
                className="mkdf-icon-widget-holder"
                href="https://www.instagram.com/dbateknoloji/"
                target="_blank"
              >
                <span className="mkdf-icon-text mkdf-no-icon">INSTAGRAM</span>{" "}
              </a>
              <a
                className="mkdf-icon-widget-holder"
                href="https://www.facebook.com/dbateknoloji/"
                target="_blank"
              >
                <span className="mkdf-icon-text mkdf-no-icon">FACEBOOK</span>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </footer>
  );
};
